import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import PageWrapper from '../Dashboard/PageWrapper/page-wrapper';
import TermsAndConditionsModal from '../Modals/TermsAndConditionsModal';
import LoaderWithAnimatedDivs from '../Loader/loader';
//import { nucleonFetch } from '../../helper/nucleonFetch';

const DashboardLayout = () => {
  const [accountDetails, setAccountDetails] = useState(null);
  const [isAccountDetailsLoading, setIsAccountDetailsLoading] = useState(true);
  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] = useState(false);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setAccountDetails(data)
        setIsAccountDetailsLoading(false);
        if (!data.TermsAccepted) {
          setIsTermsAndConditionsModalOpen(true);
        }
      })
      .catch((error) => {
        setIsAccountDetailsLoading(false);
        console.error('Error:', error)
      });
  }, []);

  const acceptTermsAndConditions = () => {
    fetch(`${process.env.REACT_APP_API_URL}/dashboard/accept_terms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        setAccountDetails(data)
        setIsAccountDetailsLoading(false);
        if (!data.TermsAccepted) {
          setIsTermsAndConditionsModalOpen(true);
        }
      })
      .catch((error) => {
        setIsAccountDetailsLoading(false);
        console.error('Error:', error)
      });
  }

  const handleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/logout`,
        { method: 'POST', credentials: 'include' }
      );
      if (response.ok) {
        const logoutUri = encodeURIComponent(
          process.env.REACT_APP_LOGOUT_REDIRECT_URI
        );
        const logoutUrl = `${process.env.REACT_APP_AUTH_DOMAIN}/logout?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&logout_uri=${logoutUri}`;
        window.location.assign(logoutUrl);
      } else {
        throw new Error('Failed to clear server-side session');
      }
    } catch (error) {
      console.error('Logout failed:', error);
      alert('Logout failed. Please try again.');
    }
  };
  return (
    isAccountDetailsLoading ? (
      <LoaderWithAnimatedDivs />
    ) : (
      <Box>
        {!isAccountDetailsLoading && accountDetails && !accountDetails.TermsAccepted ? (
          <TermsAndConditionsModal
            open={isTermsAndConditionsModalOpen}
            handleClose={setIsTermsAndConditionsModalOpen}
            type={"dashboard"}
            handleSubmit={() => acceptTermsAndConditions()}
            handleLogout={() => handleLogout()}
          />
        ) : (
          <PageWrapper />
        )}
      </Box>
    )
  )
};

export default DashboardLayout;