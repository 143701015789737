import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import NucleonDataGrid from '../NucleonDataGrid/nucleon-data-grid';
import { nucleonFetch } from '../../../helper/nucleonFetch';

const UserSubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  })

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/dashboard/getUserSubmissions`, {
          method: 'POST',
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSubmissions(data);
      } catch (error) {
        console.error('Error fetching submissions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const formatDate = (dateString) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      id="dashboard-sell-flow-user-submissions-container"
      sx={{
        width: "100%",
        height: "calc(100vh - 300px)",
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <NucleonDataGrid
        rows={submissions}
        columns={[
          { field: "id", headerName: "ID", width: 90 },
          {
            field: "approval_status",
            headerName: "Submission Status",
            width: 200,
          },
          { field: "file_name", headerName: "File Name", width: 200 },
          { field: "manufacturer", headerName: "Manufacturer", width: 150 },
          { field: "test_type", headerName: "Test Type", width: 150 },
          {
            field: "description",
            headerName: "Description",
            width: 250,
            renderCell: (params) => (
              <Tooltip title={params.value}>
                <Typography sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", cursor: "default", fontFamily: "Poppins, sans-serif" }}>
                  {params.value}
                </Typography>
              </Tooltip>
            ),
          },
          { field: "part", headerName: "Part", width: 110 },
          { field: "price", headerName: "Price", width: 110 },
          {
            field: "created_at",
            headerName: "CreatedAt",
            width: 150,
            renderCell: (params) => formatDate(params.value),
          },
          {
            field: 'updated_at',
            headerName: 'UpdatedAt',
            width: 150,
            renderCell: (params) => formatDate(params.value),
          },
        ]}
        getRowId={(row) => row.id}
        total={submissions.length}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  );
};

export default UserSubmissions;