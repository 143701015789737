import { Button } from '@mui/material';
import React from 'react';

const NucleonButton = ({
  children,
  handleButtonClick,
  isActive,
  primaryColor = '#F26E21',
  disabled = false,
  href
}) => {
  const onClick = (e) => {
    if (handleButtonClick) {
      e.preventDefault(); // Prevent default to avoid full page reload if you're using SPA navigation
      handleButtonClick(e);
    }
  };

  return (
    <Button
      component={href ? 'a' : 'button'} // If href is provided, render as an anchor
      href={href}
      onClick={href ? onClick : handleButtonClick}
      sx={{
        borderRadius: '12px',
        backgroundColor: isActive ? primaryColor : '#fff',
        border: `1px solid ${primaryColor}`,
        color: isActive ? '#fff' : primaryColor,
        textAlign: 'center',
        fontSize: '14px',
        px: 2,
        py: '6px',
        cursor: 'pointer',
        fontFamily: "'Poppins', sans-serif",
        textTransform: 'capitalize',
        display: 'block',
        '&:hover': {
          backgroundColor: isActive ? primaryColor : '#fff',
          color: isActive ? '#fff' : primaryColor,
          borderColor: primaryColor,
        },
      }}
      disabled={disabled}
      variant={isActive ? 'contained' : 'text'}
    >
      {children}
    </Button>
  );
};

export default NucleonButton;
