import { useState } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useAddUserToTestResult = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const addUserToTestResult = async (payload) => {
        setStatusMessage(null);

        try {
            const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/add-usertotestresult`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({ payload })
            });

            const data = await response.json();
            if (response.ok) {
                setStatusMessage({ type: 'success', text: 'Test Result added successfully!' });
            } else {
                throw new Error(data.message || 'Failed to add test result.');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    return { addUserToTestResult, statusMessage };
};

export default useAddUserToTestResult;
