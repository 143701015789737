import React, { useState, useEffect } from "react";
import { TextField, List, ListItem, ListItemText, Box } from "@mui/material";
import { Remarkable } from "remarkable";
import "./Wiki.css";
import PageHeader from "../PageHeader/page-header";
import { nucleonFetch } from '../../../helper/nucleonFetch';

const WikiPage = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [articles, setArticles] = useState([]);
  const md = new Remarkable();

  useEffect(() => {
    const loadArticles = async () => {
      const articles = [
        {
          id: 1,
          title: "Post-Irradiation Gate Stress (PIGS) Test",
          file: "pigs.md",
        },
        { id: 2, title: "Single-Event Latchup (SEL)", file: "latchup.md" },
        { id: 3, title: "Linear Energy Transfer", file: "let.md" },
        { id: 4, title: "Total Ionizing Dose (TID)", file: "tid.md" },
        { id: 5, title: "Single-Event Effect (SEE)", file: "see.md" },
        { id: 6, title: "Single-Event Upset (SEU)", file: "seu.md" },
        { id: 7, title: "Single Event Gate Rupture (SEGR)", file: "segr.md" },
        {
          id: 8,
          title: "Enhanced Low Dose Rate Sensitivity (ELDRS)",
          file: "eldrs.md",
        },
      ];

      const loadedArticles = await Promise.all(
        articles.map(async (article) => {
          const response = await nucleonFetch(`/articles/${article.file}`);
          const content = await response.text();
          return { ...article, content: md.render(content) };
        })
      );

      setArticles(loadedArticles);
    };

    loadArticles();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const search = params.get("search");
    if (search) {
      setSearchQuery(search);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchQuery) {
      params.set("search", searchQuery);
    } else {
      params.delete("search");
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, "", newUrl);
  }, [searchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredArticles = articles.filter((article) =>
    article.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box id="dashboard-wiki-section">
      <PageHeader
        title="Wiki"
        breadcrumbs={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Wiki", href: "/dashboard/wiki" },
        ]}
        showButton={false}
      />
      <Box sx={{ mt: 2 }}>
        <TextField
          id="wiki-search"
          label="Search Articles"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={handleSearchChange}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black", // Set focus border color to black
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "8px",
            },
            "& .MuiInputLabel-root": {
              color: "gray", // Initial label color
              "&.Mui-focused": {
                color: "black", // Label color on focus
              },
            },
            "& .MuiInputBase-input::placeholder": {
              color: "gray", // Placeholder text color
              opacity: 1, // Ensure full opacity
            },
            "& .MuiInputBase-input:focus::placeholder": {
              color: "black", // Placeholder text color on focus
            },
          }}
        />
        <Box style={{ marginTop: 20 }}>
          <List>
            {filteredArticles.map((article) => (
              <ListItem
                style={{
                  marginTop: 20,
                  display: "flex",
                  backgroundColor: "white",
                  boxShadow:
                    "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px",
                  alignItems: "center",
                  borderRadius: "16px",
                  padding: "16px 16px 16px 24px",
                }}
                key={article.id}
                id={`article-${article.id}`}
              >
                <ListItemText
                  primary={
                    <div
                      style={{ color: "#1C252E", fontSize: "1.2rem" }}
                      className="article-title"
                    >
                      {article.title}
                    </div>
                  }
                  secondary={
                    <div
                      className="article-content"
                      style={{ color: "#637381", fontSize: "0.875rem" }}
                      dangerouslySetInnerHTML={{ __html: article.content }}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default WikiPage;
