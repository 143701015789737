import { useState } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useUpdateManufacturerURLs = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const updateURLs = async () => {
        setStatusMessage(null);
        try {
            const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/scrape_manufacturer_urls`, {
                method: 'post',
                credentials: 'include'
            });
            const data = await response.json();
            if (response.ok) {
                setStatusMessage({ type: 'success', text: 'URLs successfully updated' });
            } else {
                console.log(data)
                throw new Error('Failed to update URLs.');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    // Return the function and status message
    return { updateURLs, statusMessage };
};

export default useUpdateManufacturerURLs;
