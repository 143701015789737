export const nucleonFetch = async (url, options = {}) => {
    try {
        const response = await fetch(url, options);

        if (response.status === 401) {
            redirectToLogin();
            return null;
        }

        if (!response.ok) {
            let errorMessage = `HTTP error! Status: ${response.status}`;
            try {
                const errorData = await response.json();
                if (errorData && errorData.message) {
                    errorMessage = errorData.message;
                }
            } catch (parseErr) {
                // If JSON parsing fails, fall back to default errorMessage
            }

            const error = new Error(errorMessage);
            error.status = response.status;
            throw error;
        }

        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};


const redirectToLogin = () => {
    const loginParams = new URLSearchParams({
        response_type: 'code',
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        scope: 'email openid profile',
    });

    const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
    const loginUrl = `https://${authDomain}/login?${loginParams.toString()}`;

    localStorage.setItem('redirectUrl', window.location.pathname);

    window.location.assign(loginUrl);
};