export const nucleonFetchNoRedirect = async (url, options = {}) => {
    try {
        const response = await fetch(url, { ...options, credentials: 'include' });

        if (response.status === 401) {
            // Return a special object to indicate authorization required
            return { authRequired: true };
        }

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

// Keep redirectToLogin as is
export const redirectToLogin = () => {
    const loginParams = new URLSearchParams({
        response_type: 'code',
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        scope: 'email openid profile',
    });

    const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
    const loginUrl = `https://${authDomain}/login?${loginParams.toString()}`;

    localStorage.setItem('redirectUrl', window.location.pathname);
    window.location.assign(loginUrl);
};
