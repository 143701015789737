import { useState } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useAddXRay = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const addXRay = async (payload) => {
        setStatusMessage(null);

        try {
            const response = await nucleonFetch(
                `${process.env.REACT_APP_API_URL}/operations/addXRay`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                    body: JSON.stringify({ payload }),
                }
            );

            const data = await response.json();
            if (response.ok) {
                setStatusMessage({
                    type: 'success',
                    text: 'X-Ray added successfully!',
                });
            } else {
                throw new Error(data.message || 'Failed to add X-Ray');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    return { addXRay, statusMessage };
};

export default useAddXRay;