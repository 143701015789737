import { useState, useEffect } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useFetchMPNs = () => {
    const [mpns, setMPNs] = useState([]);

    useEffect(() => {
        const fetchMPNs = async () => {
            try {
                const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/mpns`, {
                    method: 'post',
                    credentials: 'include'
                });
                const data = await response.json();
                if (response.ok) {
                    setMPNs(data);
                } else {
                    throw new Error('Failed to fetch mpn');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        fetchMPNs();
    }, []);

    return mpns;
};

export default useFetchMPNs;