import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Plot from "react-plotly.js";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";
import { nucleonFetch } from '../../../helper/nucleonFetch';

const TIDCumulativePlot = () => {
  const [plotData, setPlotData] = useState({});

  useEffect(() => {
    nucleonFetch(`${process.env.REACT_APP_API_URL}/dashboard/get_TID_cumulative_plot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => setPlotData(data))
      .catch(error => console.error('Error:', error));
  }, []);


  const data = [
    {
      x: Object.keys(plotData),
      y: Object.values(plotData),
      mode: "lines+markers",
      type: "scatter",
      marker: {
        color: "red",
      },
    },
  ];

  const layout = {
    title: "TID Pass Percentage - Commercial Parts",
    xaxis: {
      title: "TID Value (krad)",
    },
    yaxis: {
      title: "Percentage",
      range: [0, 100], // Ensure y-axis goes from 0 to 100%
    },
    autosize: true,
  };

  return (
    <NucleonWrapper sx={{ mt: 5 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontFamily: "'Poppins', sans-serif", color: "#1C252E" }}
      >
        Cumulative TID Pass Percentage
      </Typography>
      {Object.keys(plotData).length > 0 && (
        <Plot
          divId='tid_cumulative_plot'
          data={data}
          layout={layout}
          style={{ width: "100%", height: "400px" }}
        />
      )}
    </NucleonWrapper>
  );
};

export default TIDCumulativePlot;
