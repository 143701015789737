import { useState, useEffect } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useFetchTestResultsFromDataSource = (dataSourceName) => {
    const [testResults, setTestResults] = useState(null);

    console.log("Fetching test Results in useFetchTestResult")
    useEffect(() => {
        const fetchTestResultsFromDataSource = async () => {
            try {
                console.log('Fetching Test Results from Data Source:', dataSourceName); // Debugging log
                const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/fetch-testresultfromdatasource`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({ name: dataSourceName })
                });
                const data = await response.json();
                console.log('Response data:', data); // Debugging log

                if (response.ok) {
                    setTestResults(data);
                } else {
                    throw new Error(data.message || 'Failed to fetch test result');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        if (dataSourceName) {
            fetchTestResultsFromDataSource();
        }
    }, [dataSourceName]);

    return testResults;
};

export default useFetchTestResultsFromDataSource;
