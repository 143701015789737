import { useState, useEffect } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useFetchCategories = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/categories`, {
                    method: 'post',
                    credentials: 'include'
                });
                const data = await response.json();
                if (response.ok) {
                    setCategories(data);
                } else {
                    throw new Error('Failed to fetch mpn');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };

        fetchCategories();
    }, []);

    return categories;
};

export default useFetchCategories;