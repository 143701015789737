import { useState, useEffect } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useFetchHeavyIonExperiments = (datasourceTitle, testResultName) => {
    const [details, setDetails] = useState([]);

    useEffect(() => {
        const fetchHeavyIonExperiments = async () => {
            try {
                const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/dashboard/partdetails/heavy_ion_experiments`, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ datasourceTitle: datasourceTitle, testResultName: testResultName})
                });
                const data = await response.json();
                if (response.ok) {
                    setDetails(data);
                } else {
                    throw new Error('Failed to fetch heavy_ion experiments.');
                }
            } catch (error) {
                console.log({ type: 'error', text: error.message });
            }
        };
        if (datasourceTitle && testResultName) {
            fetchHeavyIonExperiments();
        }
    }, [datasourceTitle, testResultName]);

    return details;
};

export default useFetchHeavyIonExperiments;