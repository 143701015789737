import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { sanitizeInput } from '../../helper/helper';
import { nucleonFetch } from '../../helper/nucleonFetch';

const AddManufacturerForm = () => {
  const [manufacturerName, setManufacturer] = useState('');
  const [statusMessage, setStatusMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/add-manufacturer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          manufacturerName: manufacturerName,
        })
      });

      if (response.ok) {
        setStatusMessage({ type: 'success', text: 'Manufacturer added successfully!' });
        setManufacturer(''); // Reset the input field on success
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add manufacturer');
      }
    } catch (error) {
      setStatusMessage({ type: 'error', text: error.message });
    }
  };

  return (
    <Box
      id='add-manufacturer-form'
      component="form"
      onSubmit={handleSubmit}
    >
      <TextField
        id="manufacturer-name-input"
        style={{
          width: "100%",
          marginRight: "0",
          marginBottom: "10px",
          marginTop: "20px"
        }}
        label="Manufacturer Name"
        value={manufacturerName}
        onChange={(e) => setManufacturer(sanitizeInput(e.target.value))}
        variant="outlined"
        sx={{
          "& .MuiOutlinedInput-root": {
            borderRadius: 2,
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(145, 158, 171, 0.8)",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 2,
            borderColor: "rgba(145, 158, 171, 0.2)",
          },
          "& .MuiInputLabel-root": {
            color: "rgba(145 158 171)",
            "&.Mui-focused": {
              color: "black",
            },
          },
          "& .MuiInputBase-input::placeholder": {
            color: "rgba(145, 158, 171, 0.8)",
            opacity: 1,
          },
          "& .MuiInputBase-input:focus::placeholder": {
            color: "rgba(145, 158, 171, 0.8)",
          },
        }}
      />

      <Box sx={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
        <Button
          type="submit"
          sx={{
            mt: 3,
            mb: 1,
            borderRadius: "12px",
            backgroundColor: "#F26E21",
            color: "#fff",
            textAlign: "center",
            fontSize: "14px",
            px: 2,
            py: 2,
            minWidth: 200,
            height: "48px",
            cursor: "pointer",
            fontFamily: "'Poppins', sans-serif",
            textTransform: "capitalize",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              backgroundColor: "#F26E21",
              boxShadow: "none",
            },
            "&:active": {
              backgroundColor: "#F26E21",
              boxShadow: "none",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "#F26E21",
              boxShadow: "none",
            },
          }}
        >
          Add Manufacturer
        </Button>
      </Box>

      {statusMessage && (
        <div
          style={{ color: statusMessage.type === "success" ? "green" : "red", marginTop: "10px" }}
        >
          {statusMessage.text}
        </div>
      )}
    </Box>
  );
};

export default AddManufacturerForm;
