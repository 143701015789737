import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  //   DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import AddBOMForm from "./AddBOMForm";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import useTrialUserCheck from "../../TrialUserCheck/useTrialUserCheck";
import RestrictedFeatureDialog from "../../TrialUserCheck/RestrictedFeatureDialog";
import PageHeader from "../PageHeader/page-header";
import NucleonDataGrid from "../NucleonDataGrid/nucleon-data-grid";
// import { BILL_OF_MATERIAL } from "../../../mock/billofmaterial";
import NucleonButton from "../NucleonButton/nucleon-button";
import { nucleonFetch } from '../../../helper/nucleonFetch';

const BillOfMaterials = () => {
  const [boms, setBoms] = useState([]);
  const [open, setOpen] = useState(false);
  const { isTrialUser, isLoading } = useTrialUserCheck();

  const navigate = useNavigate(); // Create navigate function

  useEffect(() => {
    fetchBOMs();
  }, []);

  const fetchBOMs = async () => {
    const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/dashboard/get_boms`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    const data = await response.json();
    setBoms(data);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleAddBOM = (newBOM) => {
    setBoms([...boms, newBOM]);
    handleClose();
  };

  const handleDetails = (bomId) => {
    navigate(`/dashboard/bill-of-materials/${bomId}`); // Navigate to BOM details page using bom_id
  };

  const handleDelete = async (bomId) => {
    // Send a DELETE request to the backend
    const response = await nucleonFetch(
      `${process.env.REACT_APP_API_URL}/dashboard/delete_bom/${bomId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    if (response.ok) {
      // If the deletion was successful, filter out the deleted BOM
      const updatedBoms = boms.filter((bom) => bom.bom_id !== bomId);
      setBoms(updatedBoms);
    } else {
      // Handle error, e.g., show a message
      alert("Failed to delete the BOM.");
    }
  };

  const columns = [
    // { field: 'bom_id', headerName: 'ID', width: 90 },
    { field: "bom_name", headerName: "Name", width: 150 },
    { field: "user_email", headerName: "User Email", width: 260 },
    { field: "user_affiliation", headerName: "User Affiliation", width: 180 },
    { field: "created_at", headerName: "Created At", width: 300 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <Box sx={{ display: "flex", gap: 4 }}>
          <NucleonButton isActive={true} handleButtonClick={() => handleDetails(params.row.bom_id)}>
            Details
          </NucleonButton>
          <NucleonButton primaryColor="black" isActive={true} handleButtonClick={() => handleDelete(params.row.bom_id)}>
            Delete
          </NucleonButton>
        </Box>
      ),
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isTrialUser) {
    return <RestrictedFeatureDialog open={true} />;
  }

  return (
    <Box
      id="dashboard-bill-of-materials-container"
      sx={{
        width: "100%",
        height: `calc(100vh - ${90}px)`,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <PageHeader
        title="Bill of Materials"
        breadcrumbs={[
          { label: "Dashboard", href: "/dashboard" },
          { label: "Bill of Materials", href: "/dashboard/bill-of-materials" },
        ]}
        buttonText={"Add New BOM"}
        onButtonClick={() => handleOpen()}
      />
      <NucleonDataGrid
        id="dashboard-bom-data-grid"
        rows={boms}
        columns={columns}
        paginationMode="client"
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(row) => row.bom_id}
        total={boms.length}
      />
      <Dialog
        id='bom-dialog'
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "500px",
            height: "250px",
            borderRadius: "12px",
            overflow: "hidden",
            p: 0,
            boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          },
        }}
      >
        <DialogTitle
          id="bom-dialog-title"
          sx={{
            fontSize: 22,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 500,
          }}
        >
          Add a new BOM
        </DialogTitle>
        <DialogContent>
          <AddBOMForm onAddBOM={handleAddBOM} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default BillOfMaterials;
