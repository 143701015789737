import { useState } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useDownloadAttributes = () => {
    const [statusMessage, setStatusMessage] = useState(null);

    const downloadAttributes = async () => {
        setStatusMessage(null);
        try {
            const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/download_attributes`, {
                method: 'POST',
                credentials: 'include'
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'attributes.xlsx'); // Set the file name
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); // Clean up the URL object

                setStatusMessage({ type: 'success', text: 'Attributes file downloaded successfully.' });
            } else {
                throw new Error('Failed to download attributes file.');
            }
        } catch (error) {
            setStatusMessage({ type: 'error', text: error.message });
        }
    };

    return { downloadAttributes, statusMessage };
};

export default useDownloadAttributes;
