import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  //   Paper,
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import UploadBOMForm from "./UploadBOMForm";
import ManufacturerBarChart from "./ManufacturerBarChart";
import NucleonDataGrid from "../NucleonDataGrid/nucleon-data-grid";
import NucleonWrapper from "../../Wrapper/NucleonWrapper";
import { sanitizeInput } from "../../../helper/helper";
import { nucleonFetch } from '../../../helper/nucleonFetch';
// import NucleonButton from '../NucleonButton/nucleon-button';
// import NucleonWrapper from '../../Wrapper/NucleonWrapper';

const BOMDetails = () => {
  const { bomId } = useParams();
  const navigate = useNavigate();
  const [bomDetails, setBomDetails] = useState({
    bom_id: 12,
    bom_lines: [],
    bom_name: "d ;as; dask das",
    letRequirement: null,
    tidRequirement: null,
  });
  const [loading, setLoading] = useState(true);
  const [tidRequirement, setTidRequirement] = useState("");
  const [letRequirement, setLetRequirement] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [similarParts, setSimilarParts] = useState([]);
  const [currentPart, setCurrentPart] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  useEffect(() => {
    fetchBOMDetails();
  }, [bomId]);

  const fetchBOMDetails = async () => {
    try {
      const response = await nucleonFetch(
        `${process.env.REACT_APP_API_URL}/dashboard/bom_details/${bomId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      const data = await response.json();
      setBomDetails(data);
      setTidRequirement(data.tidRequirement || "");
      setLetRequirement(data.letRequirement || "");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching BOM details:", error);
      setLoading(false);
    }
  };

  const fetchSimilarParts = async (partNumber) => {
    try {
      const response = await nucleonFetch(
        `${process.env.REACT_APP_API_URL}/dashboard/similar_parts`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ partNumber }),
          credentials: "include",
        }
      );
      const data = await response.json();
      setSimilarParts(data);
    } catch (error) {
      console.error("Error fetching similar parts:", error);
    }
  };

  const updateRequirements = async () => {
    const response = await nucleonFetch(
      `${process.env.REACT_APP_API_URL}/dashboard/update_requirements/${bomId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tidRequirement: tidRequirement,
          letRequirement: letRequirement,
        }),
        credentials: "include",
      }
    );
    if (response.ok) {
      alert("Requirements updated successfully!");
    } else {
      alert("Failed to update requirements.");
    }
  };

  const linkTestResults = async () => {
    const response = await nucleonFetch(
      `${process.env.REACT_APP_API_URL}/dashboard/link_mpns/${bomId}`,
      {
        method: "POST",
        credentials: "include",
      }
    );
    if (response.ok) {
      alert("Test results linked successfully!");
      fetchBOMDetails();
    } else {
      alert("No test results found for one or more items.");
    }
  };

  const handleOpenDialog = (partNumber, rowId) => {
    setCurrentPart(partNumber);
    setSelectedRowId(rowId);
    fetchSimilarParts(partNumber);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSimilarParts([]);
  };

  const handleSelectSimilarPart = async (partNumber) => {
    if (selectedRowId !== null) {
      const updatedBomDetails = bomDetails.bom_lines.map((row) => {
        if (row["Part Number"] === selectedRowId) {
          return { ...row, matchedMPN: partNumber };
        }
        return row;
      });

      setBomDetails({ ...bomDetails, bom_lines: updatedBomDetails });

      // Update the database with the selected matched MPN
      await nucleonFetch(
        `${process.env.REACT_APP_API_URL}/dashboard/update_matched_mpn`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            bomId,
            partNumber: selectedRowId,
            matchedMPN: partNumber,
          }),
          credentials: "include",
        }
      );

      handleCloseDialog();
    }
  };

  const columns = [
    { field: "Part Number", headerName: "Part Number", width: 150 },
    { field: "Manufacturer", headerName: "Manufacturer", width: 150 },
    { field: "Description", headerName: "Description", width: 200 },
    { field: "Quantity", headerName: "Quantity", type: "number", width: 100 },
    {
      field: "matchedMPN",
      headerName: "Matched MPN",
      width: 140,
      renderCell: (params) =>
        params.value ? (
          params.value
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleOpenDialog(
                params.row["Part Number"],
                params.row["Part Number"]
              )
            }
          >
            Find Similar
          </Button>
        ),
    },
    {
      field: "matchedManufacturer",
      headerName: "Matched Manufacturer",
      width: 220,
    },
    {
      field: "Max Passing TID",
      headerName: "Max Passing TID",
      filterable: true,
      width: 200,
      type: "number",
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value < tidRequirement
                ? "red"
                : params.value >= tidRequirement
                  ? "green"
                  : "inherit",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "Max Passing LET",
      headerName: "Max Passing LET",
      width: 200,
      renderCell: (params) => (
        <div
          style={{
            color:
              params.value < letRequirement
                ? "red"
                : params.value >= letRequirement
                  ? "green"
                  : "inherit",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "details",
      headerName: "Details",
      sortable: false,
      width: 130,
      renderCell: (params) =>
        params.row.matchedMPN ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(
                `/dashboard/parts/${params.row.matchedManufacturer}/${params.row.matchedMPN}`
              )
            }
          >
            Details
          </Button>
        ) : null,
    },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          fontSize: 24,
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600,
        }}
      >
        BOM (Bill of Materials) Details
      </Typography>
      <Typography
        component="div"
        sx={{
          fontSize: 16,
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 400,
          marginTop: "8px",
          lineHeight: 1.8, // Spacing between lines
          color: "#333", // Neutral text color
        }}
      >
        <ul
          style={{
            listStyle: "none", // Removes default bullet points
            padding: 0, // No extra padding
            margin: 0, // No extra margin
          }}
        >
          {[
            "Click Download Nucleon BOM Template",
            "Enter Information into BOM template",
            "Click Select File and choose BOM file",
            "Click Upload BOM",
            "Click Link Test Results",
          ].map((item, index) => (
            <li
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px", // Adds space between items
              }}
            >
              <span
                style={{
                  marginRight: "8px",
                  fontWeight: 600,
                  color: "#1976d2", // Adds a primary accent color
                }}
              >
                {index + 1}:
              </span>
              {item}
            </li>
          ))}
        </ul>
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        marginBottom="20px"
      >
        <Box sx={{ display: "flex", gap: 4 }}>
          <UploadBOMForm bomId={bomId} />
          <Button
            isActive={true}
            onClick={linkTestResults}
            sx={{
              ml: 4,
              mt: 2,
              mb: 2,
              borderRadius: "12px",
              backgroundColor: "#F26E21",
              color: "#fff",
              textAlign: "center",
              fontSize: "14px",
              px: 3,
              py: 1,
              cursor: "pointer",
              fontFamily: "'Poppins', sans-serif",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
            }}
          >
            Link Test Results
          </Button>
          <Button
            isActive={true}
            href="/nucleon_bom_template.xlsx"
            download="nucleon_bom_template.xlsx"
            sx={{
              ml: 4,
              mt: 2,
              mb: 2,
              borderRadius: "12px",
              backgroundColor: "#F26E21",
              color: "#fff",
              textAlign: "center",
              fontSize: "14px",
              px: 3,
              py: 1,
              cursor: "pointer",
              fontFamily: "'Poppins', sans-serif",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
            }}
          >
            Download Nucleon BOM Template
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "20px",
          }}
          textAlign="right"
        >
          <TextField
            label="TID Requirement"
            value={tidRequirement}
            onChange={(e) => setTidRequirement(sanitizeInput(e.target.value))}
            margin="normal"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set focus border color to black
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                borderColor: "rgba(145, 158, 171, 0.2)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(145 158 171)", // Initial label color
                "&.Mui-focused": {
                  color: "black", // Label color on focus
                },
              },
              "& .MuiInputBase-input::placeholder": {
                color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                opacity: 1, // Ensure full opacity
              },
              "& .MuiInputBase-input:focus::placeholder": {
                color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
              },
            }}
          />
          <TextField
            label="LET Requirement"
            value={letRequirement}
            onChange={(e) => setLetRequirement(sanitizeInput(e.target.value))}
            margin="normal"
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "black", // Set focus border color to black
                },
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(145, 158, 171, 0.8)", // Example hover effect with 0.8 opacity
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                borderColor: "rgba(145, 158, 171, 0.2)",
              },
              "& .MuiInputLabel-root": {
                color: "rgba(145 158 171)", // Initial label color
                "&.Mui-focused": {
                  color: "black", // Label color on focus
                },
              },
              "& .MuiInputBase-input::placeholder": {
                color: "rgba(145, 158, 171, 0.8)", // Placeholder text color
                opacity: 1, // Ensure full opacity
              },
              "& .MuiInputBase-input:focus::placeholder": {
                color: "rgba(145, 158, 171, 0.8)", // Placeholder text color on focus
              },
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Button
            isActive={true}
            onClick={updateRequirements}
            sx={{
              mt: 2,
              mb: 2,
              borderRadius: "12px",
              backgroundColor: "#F26E21",
              color: "#fff",
              textAlign: "center",
              fontSize: "14px",
              px: 3,
              py: 1,
              cursor: "pointer",
              fontFamily: "'Poppins', sans-serif",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
              "&:active": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
              "&.Mui-focusVisible": {
                backgroundColor: "#F26E21",
                boxShadow: "none",
              },
            }}
          >
            Update Requirements
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : bomDetails ? (
        <Box sx={{ display: "flex", flexDirection: "column", height: "600px" }}>
          <Typography
            sx={{
              fontSize: 16,
              fontFamily: "'Poppins', sans-serif",
              fontWeight: 600,
              mb: 2,
              mt: 2,
            }}
          >
            Name: {bomDetails.bom_name}
          </Typography>
          {bomDetails.bom_lines && (
            // <Box style={{ height: 800, width: "100%" }}>
            <NucleonDataGrid
              rows={bomDetails.bom_lines}
              columns={columns}
              getRowId={(row) => row['Part Number']}
              total={0}
            />
            // </Box>
          )}
        </Box>
      ) : (
        <Typography>Details not available.</Typography>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Similar Parts for {currentPart}</DialogTitle>
        <DialogContent>
          {similarParts.length > 0 ? (
            <List>
              {similarParts.map((part, index) => (
                <ListItem
                  button
                  key={index}
                  onClick={() => handleSelectSimilarPart(part.partNumber)}
                >
                  <ListItemText
                    primary={part.partNumber}
                    secondary={part.description}
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography>No similar parts found.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <NucleonWrapper sx={{ mt: 5, }} >
        {/* <Paper elevation={3} style={{ padding: '20px', margin: '20px', flexGrow: 1, overflow: 'auto' }}> */}
        <Typography sx={{
          fontSize: 24,
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600,
        }} >BOM Analysis</Typography>
        <ManufacturerBarChart bomDetails={bomDetails} />
      </NucleonWrapper>
      {/* </Paper> */}
    </Box>
  );
};

export default BOMDetails;
