import { useState, useEffect } from 'react';
import { nucleonFetchNoRedirect } from '../helper/nucleonFetchNoRedirect';

const useFetchPartDetails = (manufacturer, partNumber) => {
    const [partDetails, setPartDetails] = useState(null);
    const [similarParts, setSimilarParts] = useState([]);
    const [pcnUpdates, setPcnUpdates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [authRequired, setAuthRequired] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            try {
                const response = await nucleonFetchNoRedirect(`${process.env.REACT_APP_API_URL}/dashboard/partdetails`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ manufacturer, partNumber })
                });

                // Check if auth is required
                if (response && response.authRequired) {
                    if (isMounted) {
                        setAuthRequired(true);
                    }
                    return; // Stop processing
                }

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (isMounted) {
                    setPartDetails(data);
                    setSimilarParts(data["Similar Parts"]);
                    setPcnUpdates(data["PCN Updates"]);
                }
            } catch (error) {
                if (isMounted) {
                    setError(error.message);
                    console.error('Error:', error);
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [manufacturer, partNumber]);

    return { partDetails, similarParts, pcnUpdates, isLoading, error, authRequired };
};

export default useFetchPartDetails;
