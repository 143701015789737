import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

import PartInformation from './PartInformation';
import PartRadiationSummary from './PartRadiationSummary';
import PartDetailsTestResults from './PartDetailsTestResults';
import PartParametricInfo from './PartParametricInfo';
import PartChangeNotification from '../PartChangeNotification/PartChangeNotification';
import SimilarParts from './PartDetailsSimilarParts';
import PartXRay from './PartXRay';


// Hooks
import useFetchAdmin from '../../../hooks/useFetchAdmin';
import useFetchPartDetails from '../../../hooks/useFetchPartDetails';
import PageHeader from '../PageHeader/page-header';
import { redirectToLogin } from '../../../helper/nucleonFetchNoRedirect';

const PartDetails = () => {
    const { manufacturer, partNumber } = useParams();
    const isAdmin = useFetchAdmin();
    const { partDetails, similarParts, pcnUpdates, isLoading, error, authRequired } = useFetchPartDetails(manufacturer, partNumber); // eslint-disable-line
    //const partDetails = PART_DETAILS;

    const [showAuthModal, setShowAuthModal] = useState(false);

    useEffect(() => {
        if (authRequired) {
            setShowAuthModal(true);
        }
    }, [authRequired]);

    return (
        <Box>
            <PageHeader
                title={`Part Details: ${manufacturer} ${partNumber}`}
                breadcrumbs={[
                    { label: 'Dashboard', href: '/dashboard' },
                    { label: partNumber, href: `/dashboard/parts/${manufacturer}/${partNumber}` },
                ]}
                showButton={false}
            />
            {showAuthModal && (
                <Dialog
                    id="auth-dialog-56789"
                    open={showAuthModal}
                    onClose={() => setShowAuthModal(false)}
                    maxWidth="md"
                    fullWidth
                    sx={{
                        '& .MuiDialog-paper': {
                            border: '2px solid #000',    // Outline around the modal
                            padding: '20px',
                            backgroundColor: '#f9f9f9'   // Slightly off-white background
                        }
                    }}
                >
                    <DialogTitle id="auth-dialog-title-56789" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        Authentication Required
                    </DialogTitle>
                    <DialogContent id="auth-dialog-content-56789" sx={{ textAlign: 'center' }}>
                        <Typography variant="body1">
                            This page requires you to be logged in. Would you like to log in now or go to the home page?
                        </Typography>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', gap: '16px' }}>
                        <Button
                            id="login-btn-56789"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowAuthModal(false);
                                redirectToLogin();
                            }}
                        >
                            Go to Login/Register
                        </Button>
                        <Button
                            id="home-btn-56789"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setShowAuthModal(false);
                                window.location.assign('/');
                            }}
                        >
                            Go to Nucleon Homepage
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {partDetails && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <PartInformation partDetails={partDetails} isAdmin={isAdmin} />
                            {partDetails['X-Ray Logs'].length ? (
                                <PartXRay partDetails={partDetails} isAdmin={isAdmin} />
                            ) : null}
                            <PartParametricInfo partDetails={partDetails} isAdmin={isAdmin} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PartRadiationSummary partDetails={partDetails} />
                            <PartDetailsTestResults testResults={partDetails["Test Results"]} isAdmin={isAdmin} />
                        </Grid>
                    </Grid>
                    {pcnUpdates?.length > 0 && (
                        <PartChangeNotification pcn_updates={pcnUpdates} />
                    )}
                    {similarParts && similarParts.length > 0 && (
                        <SimilarParts similarParts={similarParts} />
                    )}
                </>
            )}
        </Box>
    );
};

export default PartDetails;