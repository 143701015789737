import { useEffect, useState } from 'react';
import { nucleonFetch } from '../../helper/nucleonFetch';

const useTrialUserCheck = () => {
    const [isTrialUser, setIsTrialUser] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkTrialUser = async () => {
            try {
                const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/dashboard/check_trial`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (response.ok) {
                    const data = await response.json();
                    setIsTrialUser(data.is_trial);
                } else {
                    console.error('Failed to check trial user status');
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        };

        checkTrialUser();
    }, []);

    return { isTrialUser, isLoading };
};

export default useTrialUserCheck;