import React, { useState, useEffect } from 'react';
import Button from '../Button/button';
import { nucleonFetch } from '../../helper/nucleonFetch';

const StatsCardComponent = () => {
  const [stats, setStats] = useState({
    parts: 5071,
    testResults: 6607,
    manufacturers: 413
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/public/get_database_stats`, {
          method: 'GET',
        });
        const data = await response.json();
        if (response.ok) {
          setStats({
            parts: data.part_count,
            testResults: data.test_result_count,
            manufacturers: data.mfg_count,
          });
        } else {
          throw new Error('Failed to fetch stats');
        }
      } catch (error) {
        console.log({ type: 'error', text: error.message });
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="">
      <div className="flex flex-col w-full">
        <p className="card-text fs-5 mb-3">Number of parts: {stats.parts}</p>
        <p className="card-text fs-5 mb-3">Number of test results: {stats.testResults}</p>
        <p className="card-text fs-5 mb-3">Number of manufacturers: {stats.manufacturers}</p>
        <Button title="Subscribe Today" />
      </div>
    </div>
  );
};

export default StatsCardComponent;