import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import blogs from './blogs.json';
import { Box, Typography, Chip, Container } from '@mui/material';
import { styled } from '@mui/system';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const renderContent = (content) => {
  return content.map((element, index) => {
    switch (element.type) {
      case 'header':
        return (
          <Typography
            sx={{
              color: '#1C252E',
              fontFamily: "'Poppins', sans-serif",
              fontSize: '24px',
            }}
            variant="h4"
            key={index}
            gutterBottom
          >
            {element.value}
          </Typography>
        );
      case 'subheading':
        return (
          <Typography
            sx={{ color: '#1C252E', fontFamily: "'Poppins', sans-serif" }}
            variant="h5"
            key={index}
            gutterBottom
          >
            {element.value}
          </Typography>
        );
      case 'paragraph':
        return (
          <Typography
            variant="body1"
            key={index}
            paragraph
            sx={{ color: '#637381', fontFamily: "'Poppins', sans-serif" }}
          >
            {element.value}
          </Typography>
        );
      case 'list':
        return element.ordered ? (
          <ol style={{ paddingLeft: 16 }} key={index}>
            {element.items.map((item, itemIndex) => (
              <li
                style={{
                  color: '#1C252E',
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: '0.9rem',
                  lineHeight: 2,
                }}
                key={itemIndex}
              >
                {item}
              </li>
            ))}
          </ol>
        ) : (
          <ul style={{ paddingLeft: 16 }} key={index}>
            {element.items.map((item, itemIndex) => (
              <li
                style={{
                  color: '#1C252E',
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: '0.9rem',
                  lineHeight: 2,
                }}
                key={itemIndex}
              >
                {item}
              </li>
            ))}
          </ul>
        );
      case 'image':
        return (
          <LazyLoadImage
            key={index}
            effect="blur"
            alt={element.alt}
            src={element.src}
            style={{
              objectFit: 'cover',
              borderRadius: '8px',
              width: '100%',
              marginBottom: '24px',
              margin: '20px 0px',
            }}
          />
        );
      case 'video':
        return (
          <Box
            sx={{
              my: 2,
              width: '100%',
              height: 'auto',
              borderRadius: '12px',
              overflow: 'hidden',
            }}
            key={index}
          >
            <video controls style={{ width: '100%', borderRadius: '12px' }}>
              <source src={element.src} type="video/mp4" />
              {element.alt}
            </video>
          </Box>
        );
      default:
        return null;
    }
  });
};

const StatusChip = styled(Chip)(({ theme }) => ({
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: 1,
  backgroundColor: '#e0f7fa',
  color: '#00796b',
}));

const BlogPostPage = () => {
  if (blogs?.length === 0) {
    return <Navigate to="/" />;
  }
  const { slug } = useParams();
  const blog = blogs.find((blog) => blog.slug === slug);

  if (!blog) {
    return <Typography variant="h6">Blog not found</Typography>;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ fontFamily: 'Poppins, sans-serif', py: 3, my: 5 }}
    >
      <Box
        sx={{
          padding: '24px',
          borderRadius: '20px',
          overflow: 'hidden',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3,
            position: 'relative',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              fontWeight="400"
              fontSize="14px"
              color="textSecondary"
              sx={{ marginRight: 'auto' }}
            >
              {blog.createdAt}
            </Typography>
            <StatusChip label="Published" />
          </Box>
        </Box>
        <Typography
          variant="h3"
          component="div"
          fontWeight="600"
          gutterBottom
          sx={{ color: '#1C252E' }}
        >
          {blog.title}
        </Typography>
        {renderContent(blog.content)}
      </Box>
    </Container>
  );
};

export default BlogPostPage;