import { useState, useEffect } from 'react';
import { nucleonFetch } from '../helper/nucleonFetch';

const useFetchPartInformation = (partNumber, manufacturer) => {
    const [partInformation, setPartInformation] = useState(null);

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {

            try {
                const response = await nucleonFetch(`${process.env.REACT_APP_API_URL}/operations/fetch-partinformation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                    body: JSON.stringify({ partNumber, manufacturer })
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                if (isMounted) {
                    setPartInformation(data);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };

    }, [partNumber, manufacturer]);

    return { partInformation };
};

export default useFetchPartInformation;