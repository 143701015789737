import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Box,
  Button,
  Tooltip,
  Typography,
} from '@mui/material';
import NucleonDataGrid from '../Dashboard/NucleonDataGrid/nucleon-data-grid';
// import NucleonButton from "../Dashboard/NucleonButton/nucleon-button";
// import { Height } from "@mui/icons-material";
import { nucleonFetch } from '../../helper/nucleonFetch';

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const SubmissionsTable = () => {
  const [submissions, setSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await nucleonFetch(
          `${process.env.REACT_APP_API_URL}/dashboard/getUserSubmissions`,
          {
            method: 'POST',
            credentials: 'include',
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSubmissions(data);
      } catch (error) {
        console.error('Error fetching submissions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubmissions();
  }, []);

  const handleStatusChange = async (id, status) => {
    try {
      const response = await nucleonFetch(
        `${process.env.REACT_APP_API_URL}/dashboard/approve_part/${id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status }),
          credentials: 'include',
        }
      );

      if (response.ok) {
        const data = await response.json();
        const updatedSubmission = data.submission;
        setSubmissions((prevSubmissions) =>
          prevSubmissions.map((submission) =>
            submission.id === id ? updatedSubmission : submission
          )
        );
        alert(`Submission ${status} successfully.`);
      } else {
        alert('Failed to update submission status.');
      }
    } catch (error) {
      console.error('Error updating submission status:', error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      id='dashboard-admin-submissions-table'
      sx={{
        // height: "100%",
        display: 'flex',
        height: 'calc(100vh - 320px)',
        flexDirection: 'column',
        flexGrow: 1,
      }}
    >
      <NucleonDataGrid
        rows={submissions}
        columns={[
          { field: 'id', headerName: 'ID', width: 90 },
          {
            field: 'approval_status',
            headerName: 'Submission Status',
            width: 200,
          },
          { field: 'file_name', headerName: 'File Name', width: 250 },
          { field: 'manufacturer', headerName: 'Manufacturer', width: 150 },
          { field: 'test_type', headerName: 'Test Type', width: 150 },
          {
            field: 'description',
            headerName: 'Description',
            width: 250,
            renderCell: (params) => (
              <Tooltip title={params.value}>
                <Typography
                  sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    cursor: 'default',
                    fontFamily: 'Poppins, sans-serif',
                  }}
                >
                  {params.value}
                </Typography>
              </Tooltip>
            ),
          },
          { field: 'part', headerName: 'Part', width: 150 },
          { field: 'price', headerName: 'Price', width: 110 },
          {
            field: 'created_at',
            headerName: 'CreatedAt',
            width: 150,
            renderCell: (params) => formatDate(params.value),
          },
          {
            field: 'updated_at',
            headerName: 'UpdatedAt',
            width: 150,
            renderCell: (params) => formatDate(params.value),
          },
          {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: (params) => (
              <Box sx={{ display: 'flex', gap: 3 }}>
                <Button
                  variant="contained"
                  onClick={() => handleStatusChange(params.row.id, 'approved')}
                  sx={{
                    borderRadius: '12px',
                    backgroundColor: '#F26E21',
                    color: '#FFF',
                    borderColor: '#F26E21',
                    textAlign: 'center',
                    fontSize: '14px',
                    px: 2,
                    py: '6px',
                    cursor: 'pointer',
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: 'capitalize',
                    display: 'block',
                    '&:hover': {
                      backgroundColor: '#F26E21',
                      color: '#FFF',
                      borderColor: '#F26E21',
                    },
                  }}
                >
                  Approved
                </Button>

                <Button
                  variant="contained"
                  onClick={() => handleStatusChange(params.row.id, 'rejected')}
                  sx={{
                    borderRadius: '12px',
                    backgroundColor: '#000',
                    border: `1px solid  #000`,
                    color: '#fff',
                    textAlign: 'center',
                    fontSize: '14px',
                    px: 2,
                    py: '6px',
                    cursor: 'pointer',
                    fontFamily: "'Poppins', sans-serif",
                    textTransform: 'capitalize',
                    display: 'block',
                    '&:hover': {
                      backgroundColor: '#000',
                      color: '#fff',
                      borderColor: '#000',
                    },
                  }}
                >
                  Reject
                </Button>
              </Box>
            ),
          },
        ]}
        getRowId={(row) => row.id}
        total={submissions.length}
      />
    </Box>
  );
};

export default SubmissionsTable;
